<template>
  <div class="signup-bot-main d-flex">
    <v-row>
      <v-col cols="8" class="w-70 mx-auto">
        <div
          class="
            d-flex
            main-height
            flex-column
            align-center
            justify-center
            flex-grow-1
          "
        >
          <img lazy-src="logo" :src="topLogo" class="mb-2" />
          <div class="">
            <h3 class="my-4 text-center mx-auto" style="width: 350px">
              Create your account and discover a world of endless possibilities!
            </h3>

            <v-card
              ref="form"
              class="mx-auto"
              :style="
                $vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width:400px'
              "
            >
              <LoadingComponent overlay v-if="pageLoading" />
              <h1 class="text-center pt-4">Sign Up</h1>
              <v-form ref="signup" :model="signup">
                <v-card-text class="px-6">
                  <!-- <v-text-field
                    height="32"
                    dense
                    outlined
                    label="First Name"
                    :rules="[rules.required]"
                    v-model="signup.first_name"
                  ></v-text-field>
                  <v-text-field
                    height="32"
                    dense
                    outlined
                    label="Last Name"
                    :rules="[rules.required]"
                    v-model="signup.last_name"
                  ></v-text-field>

                  <v-text-field
                    height="32"
                    dense
                    outlined
                    label="User Name"
                    :rules="[rules.required]"
                    v-model="signup.username"
                  ></v-text-field> -->

                  <v-text-field
                    height="32"
                    dense
                    outlined
                    label="Email Address"
                    :rules="[rules.required]"
                    v-model="signup.email"
                  ></v-text-field>

                  <v-text-field
                    height="32"
                    dense
                    outlined
                    v-model="signup.password"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="showPass ? 'text' : 'password'"
                    name="input-10-1"
                    label="Password"
                    @click:append="showPass = !showPass"
                    v-on:keyup.enter="keyEnter"
                  ></v-text-field>

                  <v-text-field
                    height="32"
                    dense
                    outlined
                    v-model="signup.confirmPassword"
                    :append-icon="showPassConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="showPassConfirm ? 'text' : 'password'"
                    name="input-10-1"
                    label="Confirm Password"
                    @click:append="showPassConfirm = !showPassConfirm"
                    v-on:keyup.enter="keyEnter"
                  ></v-text-field>
                  <v-btn
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="primary"
                    block
                    @click="submitRegister()"
                  >
                    Create new account
                  </v-btn>
                </v-card-text>
              </v-form>

              <v-divider class="mt-2"></v-divider>
              <div
                @click="goTo('/sign-in')"
                class="pointer text-center pa-4 underline font-weight-bold"
              >
                Already Got an Account? - Sign In
              </div>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import topLogoImage from "@/assets/logos/pinoybot-200px.png";
import Strings from "@/helpers/strings";
import { mapActions } from "vuex";
import LoadingComponent from "@/components/helpers/Loading";

export default {
  name: "signup",
  components: {
    LoadingComponent,
  },

  data() {
    return {
      showPass: false,
      showPassConfirm: false,
      pageLoading: false,

      signup: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
      },

      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => Strings.validEmail(value),
      },
    };
  },

  computed: {
    topLogo() {
      return topLogoImage;
    },
    inviteCode() {
      return _.get(this.$route, "query.code");
    },
    inviteRole() {
      return _.get(this.$route, "query.role");
    },
  },
  methods: {
    ...mapActions("auth", ["register", "login", "getCurrentUser"]),

    keyEnter() {
      this.submitRegister();
    },
    validateSignup() {
      return this.$refs["signup"].validate();
    },
    submitRegister() {
      if (this.signup.password != this.signup.confirmPassword) {
        globalErrorHandler(
          "",
          "Your password and confirmation password do not match."
        );
        return;
      }
      if (!this.validateSignup()) {
        return;
      }
      this.registerUser(this.signup);
    },

    registerUser(data) {
      console.log("data reg", data);
      const signupData = {
        first_name: data.first_name,
        last_name: data.last_name,
        username: data.username,
        email: data.email,
        password: data.password,
      };
      this.pageLoading = true;
      this.register(signupData).then(
        async (res) => {
          this.loginAsUser({
            username: signupData.email,
            password: signupData.password,
          });
          this.$refs.signup.reset();
        },
        (err) => {
          globalErrorHandler(err, "Error Registering!");
          this.pageLoading = false;
        }
      );
    },

    loginAsUser(data) {
      const signinData = {
        username: data.username,
        password: data.password,
      };
      if (this.inviteCode) {
        this.$set(signinData, "invite_key", this.inviteCode);
      }
      if (this.inviteRole) {
        this.$set(signinData, "role", this.inviteRole);
      }
      this.login(signinData).then(
        (res) => {
          this.getCurrentUser().then(
            (resCurrentUser) => {
              this.pageLoading = false;
              globalMessageHandler("Successfully Registered.");
              this.goTo("/dashboard/playground", true);
            },
            (err) => {
              this.pageLoading = false;
            }
          );
        },
        async (err) => {
          globalErrorHandler(err, "Error logging in!");
          this.pageLoading = false;
        }
      );
    },

    goTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.signup-bot-main {
  background: white;
}
.main-height {
  height: calc(100vh - 56px);
  max-height: calc(100vh - 56px);
}
</style>
